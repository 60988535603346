<template>
  <div>
    <div class="main-container">
      <ModalLogin />
    </div>
  </div>
</template>

<script>
import ModalLogin from "../components/ModalLogin";

export default {
  name: "Login",
  components: {
    ModalLogin: ModalLogin
  }
};
</script>
