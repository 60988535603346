<template>
  <section
    class="list list-new-destaques solucoes top-mobile pricing-container fadeIn"
    style="background-color: white; margin-bottom: 0"
  >
    <div class="container" style="width: 100%">
      <!-- Start Pricing Area -->
      <section id="pricing" class="pricing-area pt-100 pb-70">
        <div class="container">
          <div
            class="section-title wow animate__animated animate__fadeInUp"
            data-wow-delay=".3s"
          >
            <h2 class="title1 title-solucao">
              <span
                class="shadow-span"
                style="background-color: var(--cor-cinza-claro)"
              >
                Planos a partir de:
              </span>
            </h2>
            <div class="bar"></div>
            <p style="text-align: center; color: #6e6e6e">
              O valor real da mensalidade é calculado após a reunião de
              levantamento de requisitos.
            </p>
          </div>

          <div class="tab pricing-list-tab">
            <ul class="tabs">
              <li>
                <a href="#"> Anual </a>
              </li>
              <li>
                <a href="#"> Equity </a>
              </li>
            </ul>

            <div class="tab_content">
              <div class="tabs_item">
                <div class="row">
                  <p style="text-align: center; color: #6e6e6e">
                    A Shinier possui uma proposta diferenciada para projetos com
                    o perfil de impacto social e viabilidade de retorno de médio
                    prazo. A equipe Shinier se coloca à disposição para oferecer
                    todo nosso valor como desenvolvedores de tecnologia,
                    consultores no ramo de empreendimento tecnológico,
                    experiência na realização e crescimento escalar do seu
                    projeto.
                  </p>
                  <div class="col-lg-4 col-md-6">
                    <div class="single-pricing-table card-shadow">
                      <div class="pricing-header">
                        <h3>MVP</h3>
                      </div>

                      <div class="price"><sup></sup>240 <sub> horas</sub></div>

                      <ul class="pricing-features">
                        <li>
                          <i class="fas fa-check"></i>
                          Desenvolvimento do Projeto
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Aceleração especializada
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Entrega em 3 meses
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Reuniões Periódicas
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'times']"
                          ></font-awesome-icon>
                          Novas funcionalidades
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'times']"
                          ></font-awesome-icon>
                          Pacote de horas adicional
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'times']"
                          ></font-awesome-icon>
                          Acesso a investidores
                        </li>
                      </ul>

                      <div class="btn pricing-btn">
                        <a
                          @click="sendMsg('Quero começar um MVP')"
                          class="default-btn"
                        >
                          Quero começar
                          <span></span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6">
                    <div class="single-pricing-table card-shadow">
                      <div class="pricing-header">
                        <h3>Beta</h3>
                      </div>

                      <div class="price"><sup></sup>480 <sub> horas</sub></div>

                      <ul class="pricing-features">
                        <li>
                          <i class="fas fa-check"></i>
                          Desenvolvimento do Projeto
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Aceleração especializada
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Entrega em 6 meses
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Reuniões Periódicas
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Novas funcionalidades
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'times']"
                          ></font-awesome-icon>
                          Pacote de horas adicional
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'times']"
                          ></font-awesome-icon>
                          Acesso a investidores
                        </li>
                      </ul>

                      <div class="btn pricing-btn">
                        <a
                          @click="sendMsg('Quero começar uma versão beta')"
                          class="default-btn"
                        >
                          Quero automatizar
                          <span></span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6">
                    <div class="single-pricing-table card-shadow">
                      <div class="pricing-header">
                        <h3>v1</h3>
                      </div>

                      <div class="price"><sup></sup>960 <sub> horas</sub></div>

                      <ul class="pricing-features">
                        <li>
                          <i class="fas fa-check"></i>
                          Desenvolvimento do Projeto
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Aceleração especializada
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Entrega em 9 meses
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Reuniões Periódicas
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Novas funcionalidades
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Pacote de horas adicional
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Acesso a investidores
                        </li>
                      </ul>

                      <div class="btn pricing-btn">
                        <a
                          @click="sendMsg('Quero começar a v1')"
                          class="default-btn"
                        >
                          Quero escalar
                          <span></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tabs_item">
                <div class="row">
                  <p style="text-align: center; color: #6e6e6e">
                    Para essa proposta calculamos o Valuation do negócio
                    chegando ao valor post-money e estabelecemos as metas e
                    propostas de crescimento. Com essa estimativa checamos a
                    viabilidade do negócio e então nossa proposta de porcentagem
                    no negócio em troca de desconto no valor do projeto. Sendo
                    que o desconto no valor é de até 50%, pedimos em troca a
                    participação de 3% a 15%.
                  </p>
                  <div class="col-lg-4 col-md-6">
                    <div class="single-pricing-table card-shadow">
                      <div class="pricing-header">
                        <h3>MVP</h3>
                      </div>

                      <div class="price"><sup></sup>500 <sub> horas</sub></div>

                      <ul class="pricing-features">
                        <li>
                          <i class="fas fa-check"></i>
                          Desenvolvimento do Projeto
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Aceleração especializada
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Entrega em 3 meses
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Reuniões Periódicas
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'times']"
                          ></font-awesome-icon>
                          Novas funcionalidades
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'times']"
                          ></font-awesome-icon>
                          Pacote de horas adicional
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'times']"
                          ></font-awesome-icon>
                          Acesso a investidores
                        </li>
                      </ul>

                      <div class="btn pricing-btn">
                        <a
                          @click="sendMsg('Quero começar um MVP')"
                          class="default-btn"
                        >
                          Quero começar
                          <span></span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6">
                    <div class="single-pricing-table card-shadow">
                      <div class="pricing-header">
                        <h3>Beta</h3>
                      </div>

                      <div class="price"><sup></sup>1000 <sub> horas</sub></div>

                      <ul class="pricing-features">
                        <li>
                          <i class="fas fa-check"></i>
                          Desenvolvimento do Projeto
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Aceleração especializada
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Entrega em 6 meses
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Reuniões Periódicas
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Novas funcionalidades
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'times']"
                          ></font-awesome-icon>
                          Pacote de horas adicional
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'times']"
                          ></font-awesome-icon>
                          Acesso a investidores
                        </li>
                      </ul>

                      <div class="btn pricing-btn">
                        <a
                          @click="sendMsg('Quero começar uma versão beta')"
                          class="default-btn"
                        >
                          Quero automatizar
                          <span></span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6">
                    <div class="single-pricing-table card-shadow">
                      <div class="pricing-header">
                        <h3>v1</h3>
                      </div>

                      <div class="price"><sup></sup>2000 <sub> horas</sub></div>

                      <ul class="pricing-features">
                        <li>
                          <i class="fas fa-check"></i>
                          Desenvolvimento do Projeto
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Aceleração especializada
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Entrega em 9 meses
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Reuniões Periódicas
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Novas funcionalidades
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Pacote de horas adicional
                        </li>
                        <li>
                          <font-awesome-icon
                            :icon="['fa', 'check']"
                          ></font-awesome-icon>
                          Acesso a investidores
                        </li>
                      </ul>

                      <div class="btn pricing-btn">
                        <a
                          @click="sendMsg('Quero começar a v1')"
                          class="default-btn"
                        >
                          Quero escalar
                          <span></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
  <!-- End Pricing Area -->
</template>

<script>
import jQuery from "jquery";
export default {
  mounted() {
    (function ($) {
      $(".tab ul.tabs")
        .addClass("active")
        .find("> li:eq(0)")
        .addClass("current");
      $(".tab ul.tabs li a").on("click", function (g) {
        var tab = $(this).closest(".tab"),
          index = $(this).closest("li").index();
        tab.find("ul.tabs > li").removeClass("current");
        $(this).closest("li").addClass("current");
        tab
          .find(".tab_content")
          .find("div.tabs_item")
          .not("div.tabs_item:eq(" + index + ")")
          .slideUp();
        tab
          .find(".tab_content")
          .find("div.tabs_item:eq(" + index + ")")
          .slideDown();
        g.preventDefault();
      });
    })(jQuery);
  },
  methods: {
    sendMsg(msg) {
      window.open(
        `https://api.whatsapp.com/send?phone=5516981354817&text=Ol%C3%A1%20Marcius%20${msg}`
      );
    },
  },
};
</script>

<style scoped src="./index.css"></style>
