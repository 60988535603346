<template>
  <div>
    <div class="main-container">
      <FormsContato id="ctnModal" style="padding-top: 55px" />
    </div>
  </div>
</template>

<script>
import FormsContato from "../../components/FormsContato";

export default {
  name: "Contato",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Area de Contato Shinier",
    meta: [
      { name: "description", content: "Contato Shinier." },
      { name: "robots", content: "noindex, nofollow" }
    ]
  },
  components: {
    FormsContato: FormsContato
  }
};
</script>
