<template>
  <main role="main" class="login-page">
    <img src="../../assets/images/background-contato.png" class="backImg" />
    <div class="container ctn">
      <div class="row login">
        <div class="col-md-6">
          <h1 class="hL">CONTRATE HOJE MESMO UM CONSULTOR SHINIER!</h1>
          <div class="col-md-12">
            <a
              class="consultor-shinier openModal btnConS"
              type="button"
              href="https://wa.me/5516981354817"
            >
              <span style="padding: 0 15px">Consultor Shinier</span>
              <img src="../../assets/icons/email.svg" style="width: 32px" />
            </a>
          </div>
        </div>
        <div class="col-md-6 area-login">
          <strong>Entre com as Credenciais Corporativas</strong>

          <div align="center">
            <form
              id="form-login"
              align="center"
              class="formlogin"
              @submit.prevent="login"
            >
              <div class="margem">
                <div id="user_div" class="form_login">
                  <span id="user_div_span" class="dica"
                    >Digite sua identificação de usuário!</span
                  >
                  <img
                    src="../../assets/icons/user.jpg"
                    name="user_img"
                    width="24"
                    height="24"
                    border="0"
                    align="middle"
                  />
                  <input
                    name="user_div_field"
                    type="text"
                    id="user"
                    class="cxtext"
                    v-model="user.cpf"
                    placeholder="CPF"
                    v-mask="'###.###.###-##'"
                  />
                </div>
                <div id="pwd_div" class="form_login">
                  <span id="pwd_div_span" class="dica"
                    >Digite sua senha de acesso!</span
                  >
                  <img
                    src="../../assets/icons/key.jpg"
                    name="user_img"
                    width="24"
                    height="24"
                    border="0"
                    align="middle"
                  />
                  <input
                    name="pwd_div_field"
                    type="password"
                    id="pwd"
                    class="cxtext"
                    placeholder="Senha"
                    v-model="user.password"
                  />
                </div>
                <div id="enter_img_div" class="form_login">
                  <span id="enter_img_div_span" class="dica"
                    >Clique uma vez aqui para realizar sua autenticação!</span
                  >
                  <button class="submit-login">
                    <img src="../../assets/icons/login.svg" class="iconBtnL" />
                    <strong class="sL">Ir</strong>
                  </button>
                </div>
              </div>
            </form>
            <div id="login-form-toast" class="toast"></div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import api from "@/services";
import { toast } from "@/utils";

export default {
  data: () => ({
    user: {
      cpf: "",
      password: ""
    }
    // show: false, // password show/hide eye
  }),
  methods: {
    login: function() {
      api.post("login", this.user).then(
        response => {
          if (response.status === 200) {
            this.$store.dispatch("login", response.data);
            this.$router.replace({ path: "/" });
          }
        },
        error => {
          error.response.data.errors.forEach(msg => {
            toast(this, "is-danger", msg);
          });
          return Promise.reject(error);
        }
      );
    }
  }
};
</script>
<style>
@import url("./login.css");
</style>
