<template>
  <div>
    <div class="main-container">
      <main role="main">
        <div class="page-head head-todas-solucoes">
          <div
            class="container header"
            style="position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%); z-index: 1;"
          >
            <h1 class="title-h1" style="text-align: center;">
              SHINIER - Messages
            </h1>
          </div>
        </div>

        <div class="all-soluctions container">
          <h1 v-if="messages.length == 0">Não possui novas mensagens</h1>
          <CardMessage
            v-for="message in messages"
            :message="message"
            :key="message.id"
          />
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import api from "@/services";
import store from "../store";
import { toast } from "@/utils";
import CardMessage from "@/components/CardMessage";
export default {
  name: "Messages",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Area de Mensagens Shinier",
    meta: [
      { name: "description", content: "Mensagens recebidas." },
      { name: "robots", content: "noindex, nofollow" }
    ]
  },
  components: {
    CardMessage: CardMessage
  },
  data() {
    return {
      messages: {}
    };
  },
  methods: {
    getMessages() {
      api.get("message/index").then(
        response => {
          if (response.status === 200) {
            this.messages = response.data.messages;
          }
        },
        error => {
          error.response.data.errors.forEach(msg => {
            toast(this, "is-danger", msg);
          });
          return Promise.reject(error);
        }
      );
    }
  },
  created() {
    if (store.getters.isLogged) {
      this.getMessages();
    }
  }
};
</script>
