<template>
  <div class="all-soluction" style="width: 100%; margin: 20px 0">
    <p>
      {{ message.created_at }}
      <br />
      <span>{{ message.name }} </span>{{ message.company }} ({{
        message.office
      }}) <br />
      <i style="float: right"
        >{{ message.email }} {{ message.telephone }} {{ message.site }}</i
      >
    </p>
    <p><span>Mensagem:</span> {{ message.message }}</p>
    <p style="font-size: 12px">{{ message.tracking }} - {{ message.page }}</p>
    <a
      role="button"
      class="cli-plugin-button"
      style="
        color: rgb(255, 255, 255);
        background-color: rgb(173 135 69);
        float: right;
        bottom: 10px;
      "
      @click="deleteMessage()"
    >
      DELETAR
    </a>
  </div>
</template>
<script>
import api from "@/services";
import { toast } from "@/utils";

export default {
  props: ["message"],
  methods: {
    deleteMessage() {
      api.delete("message/delete/" + this.message.id).then(
        response => {
          if (response.status === 200) {
            toast(this, "is-success", "Deletado, favor atualizar a pagina");
          }
        },
        error => {
          error.response.data.errors.forEach(msg => {
            toast(this, "is-danger", msg);
          });
          return Promise.reject(error);
        }
      );
    }
  }
};
</script>
