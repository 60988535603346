<template>
  <div>
    <!--  <Sidebar /> -->
    <div class="main-container">
      <main role="main">
        <!-- Abre Cabeçalho -->
        <BannerHome />

        <!-- Fecha Cabeçalho -->

        <Planos />
        <!-- Abre secao de Cases -->
        <section class="list list-new-destaques solucoes">
          <div
            class="container"
            style="
              width: 100%;
              padding-bottom: 2% !important;
              padding-top: 1% !important;
            "
          >
            <h2 class="title1 title-shadow-span">
              <span class="shadow-span">
                quem
                <b class="notranslate">confia</b> na shinier
              </span>
            </h2>
            <p style="text-align: center; color: #6e6e6e">
              Nosso time já desenvolveu soluções digitais para empresas
              brasileiras e estrangeiras. Trabalhamos com excelência e
              dedicação, procurando entregar, não apenas um software sob medida,
              mas agregar tecnologia que faça diferença e cause disrupção no
              setor de atuação de nossos clientes. Independente do orçamento
              disponível estaremos disponibilizando o que há de melhor, para que
              o projeto traga retorno financeiro real e juntos iremos crescer
              cada vez mais.
            </p>

            <div class="slick-destaques slick-initialized slick-slider">
              <div aria-live="polite" class="slick-list draggable">
                <div
                  class="row slick-track"
                  style="opacity: 1; display: flex; flex-wrap: wrap"
                >
                  <CardCase
                    :lazyImg="imageCases[3].src"
                    :alt="imageCases[3].alt"
                    colMd="3"
                    icon="icon icon-ametista-color-30"
                    desc="Plataforma de Cursos e Correção de Redações Online VirtualRed, o curso Red nos procurou para desenvolvermos a plataforma web o diferencial do seu curso de redações ainda em 2019, também desenvolvemos um aplicativo e trabalhamos continuamente para melhoria da plataforma."
                    route="https://app.virtualred.com.br/"
                    mais="true"
                  />

                  <CardCase
                    :lazyImg="imageCases[1].src"
                    :alt="imageCases[1].alt"
                    colMd="3"
                    icon="icon icon-small"
                    desc="Os fundadores da Small Doações nos encontraram na fase inicial da empresa e juntos traçamos os planos de MVP, e crescimento do projeto, auxiliando a criação da start-up e tomamos a responsabilidade de desenvolvimento de toda a tecnologia."
                    route="http://smalldoacoes.com.br/"
                    mais="true"
                  />

                  <CardCase
                    :lazyImg="imageCases[2].src"
                    :alt="imageCases[2].alt"
                    colMd="3"
                    icon="icon icon-condinvest"
                    desc="A CondInvest serviços e jurídico de cobrança de condomínio nos designou a oportunidade de levar a empresa ao que a de mais moderno em soluções digitais, assim trabalhamos em uma consultoria completa, para só então integrar as melhorias na plataforma interna e um novo aplicativo para seus clientes."
                    route="http://condinvest.com.br/"
                    mais="true"
                  />

                  <CardCase
                    :lazyImg="imageCases[0].src"
                    :alt="imageCases[0].alt"
                    colMd="3"
                    icon="icon icon-acquanativa"
                    desc="A empresa de equipamentos de monitoramento ambiental AcquaNativa nos procurou para agregar o compliance de uma plataforma web, aplicativo e motores dos servidores melhorando assim a escalabilidade e experiência para seus clientes."
                    route="http://acquanativa.com.br/"
                    mais="true"
                  />
                </div>
                <div
                  class="row slick-track"
                  style="opacity: 1; display: flex; flex-wrap: wrap"
                >
                  <Carousel
                    class="carousel-container-client"
                    :autoplay="true"
                    :per-page="4"
                    easing="linear"
                    :speed="1000"
                    :autoplayHoverPause="true"
                    :perPageCustom="[
                      [480, 2],
                      [768, 3],
                      [1024, 4]
                    ]"
                    :loop="true"
                    :navigationEnabled="false"
                    :scroll-per-page="false"
                    :paginationEnabled="false"
                  >
                    <SlideNormal
                      class="carousel-slide-client"
                      v-for="(slide, i) in slideClientes"
                      :index="i"
                      :key="i"
                    >
                      <a
                        class="logo-parceiro slide-logos"
                        :href="slide.href"
                        target="_blank"
                      >
                        <img
                          v-lazy="slide.src"
                          :alt="slide.alt"
                          class="img-loaded"
                        />
                      </a> </SlideNormal
                  ></Carousel>
                </div>
                <a
                  @click="choosePlan('aceleracao')"
                  class="btn btn-orange-solid"
                  style="filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
                  >Desejo uma aceleração</a
                >
              </div>
            </div>
          </div>
        </section>

        <section
          class="list list-new-destaques solucoes"
          style="background-color: white; margin-bottom: 0"
        >
          <div class="container" style="width: 100%">
            <h2 class="title1 title-solucao">
              <span
                class="shadow-span"
                style="background-color: var(--cor-cinza-claro)"
                >Ciclo de vida da <b>Empresa</b></span
              >
            </h2>
            <p style="text-align: center; color: #6e6e6e">
              Assim como os organismos vivos, também podemos entender o
              desenvolvimento organizacional, um ser nasce, cresce e o fim se dá
              com o envelhecimento e declínio. Também há as dificuldades em cada
              etapa do ciclo de vida, principalmente, quando ocorrem as mudanças
              de estágios. Os fatores flexibilidade e controle são
              constantemente alterados no decorrer do ciclo. Os diversos
              estágios de crescimento são apresentados nesta sequência:
            </p>

            <iframe
              class="iframe-quiz-style"
              width="100%"
              height="600"
              src="https://shinier.quizclass.net/descubra-qual-fase-da-vida-sua-empresa-esta"
              title="Quiz"
              frameborder="0"
              sandbox="allow-same-origin allow-scripts allow-forms allow-popups"
              allow="camera https://shinier.quizclass.net/descubra-qual-fase-da-vida-sua-empresa-esta; microphone https://shinier.quizclass.net/descubra-qual-fase-da-vida-sua-empresa-esta%22%3E"
            ></iframe>
          </div>
        </section>

        <!-- Abre secao de Perceiros -->
        <section class="list list-new-destaques solucoes">
          <div
            class="container"
            style="
              width: 100%;
              padding-bottom: 3% !important;
              padding-top: 2% !important;
            "
          >
            <h2 class="title1 title-shadow-span">
              <span class="shadow-span">
                <b class="notranslate">parcerias</b> de sucesso
              </span>
            </h2>

            <div class="slick-destaques slick-initialized slick-slider">
              <p style="text-align: center; color: #6e6e6e">
                Ao longo destes anos encontramos empresas com que podemos contar
                para juntos entregarmos o melhor para nossos clientes.
              </p>
              <div aria-live="polite" class="slick-list draggable">
                <div class="row slick-track row-parceiros">
                  <a
                    class="logo-parceiro"
                    href="https://hubuspinovacao.if.usp.br/empresas"
                    target="_blank"
                  >
                    <img
                      v-lazy="imageParceiros[0].src"
                      :alt="imageParceiros[0].alt"
                      class="img-loaded"
                    />
                  </a>

                  <a
                    class="logo-parceiro"
                    href="https://www.cossconsulting.com/"
                    target="_blank"
                  >
                    <img
                      v-lazy="imageParceiros[1].src"
                      :alt="imageParceiros[1].alt"
                      style="height: 35px"
                      class="img-loaded"
                    />
                  </a>

                  <a
                    class="logo-parceiro"
                    href="http://aws.amazon.com/pt/"
                    target="_blank"
                  >
                    <img
                      v-lazy="imageParceiros[2].src"
                      :alt="imageParceiros[2].alt"
                      class="img-loaded"
                    />
                  </a>
                  <a
                    class="logo-parceiro"
                    href="https://www.sap.com/brazil/index.html"
                    target="_blank"
                  >
                    <img
                      v-lazy="imageParceiros[3].src"
                      :alt="imageParceiros[3].alt"
                      class="img-loaded"
                    />
                  </a>
                  <a
                    class="logo-parceiro"
                    href="https://skyhub.com.br/"
                    target="_blank"
                  >
                    <img
                      v-lazy="imageParceiros[4].src"
                      :alt="imageParceiros[4].alt"
                      class="img-loaded"
                    />
                  </a>
                  <a
                    class="logo-parceiro"
                    href="https://global-selling.mercadolibre.com/landing/about"
                    target="_blank"
                  >
                    <img
                      v-lazy="imageParceiros[5].src"
                      :alt="imageParceiros[5].alt"
                      class="img-loaded"
                    />
                  </a>
                  <a
                    class="logo-parceiro"
                    href="https://developers.bb.com.br/home"
                    target="_blank"
                  >
                    <img
                      v-lazy="imageParceiros[6].src"
                      :alt="imageParceiros[6].alt"
                      class="img-loaded"
                    />
                  </a>
                </div>
              </div>
            </div>
            <a
              @click="$router.push({ name: 'Fabrica' })"
              class="btn btn-orange-solid"
              style="filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
              >Quero ser parceiro</a
            >
          </div>
        </section>

        <!-- Abre secao das solucoes + slider-->
        <section
          class="list list-new-destaques solucoes"
          style="
            background-color: white;
            padding-bottom: 3% !important;
            padding-top: 3% !important;
            margin-bottom: 0;
          "
        >
          <div class="container" style="width: 100%">
            <h2 class="title1 title-solucao">
              <span
                class="shadow-span"
                style="background-color: var(--cor-cinza-claro)"
              >
                módulos<b> plug & play</b></span
              >
            </h2>

            <div class="slick-destaques slick-initialized slick-slider">
              <div aria-live="polite" class="slick-list draggable">
                <p style="text-align: center; color: #6e6e6e">
                  Acreditamos na democracia da tecnologia, e para isso
                  trabalhamos constantemente na melhoria e inovação de nosso
                  núcleo. Temos uma API robusta orientada a micro-serviços em
                  que facilmente conseguimos plugar nossos módulos e liberar
                  novas tecnologia para nossos clientes, permitindo assim uma
                  customização sob-medida, a custos abaixo do mercado, e
                  garantido a qualidade com desenvolvemento com frameworks
                  modernos como Lumen, Laravel, Flutter, Vue.js e etc.
                </p>

                <div class="row slick-track" style="opacity: 1" role="listbox">
                  <carousel-3d v-if="win == true" v-bind="settings">
                    <slide
                      v-for="(slide, i) in slides"
                      :index="i"
                      :key="slide.route"
                    >
                      <CardSlider
                        :id="slide.id"
                        :titleImage="slide.titleImage"
                        :title="slide.title"
                        :icon="slide.icon"
                        :desc="slide.desc"
                        :route="slide.route"
                        :class="slide.class"
                      />
                    </slide>
                  </carousel-3d>
                  <div v-if="win == false">
                    <div
                      v-for="(card, i) in cards"
                      :index="i"
                      :key="card.route"
                    >
                      <CardDestaque
                        :id="card.id"
                        :titleImage="card.titleImage"
                        :title="card.title"
                        :icon="card.icon"
                        :desc="card.desc"
                        :route="card.route"
                        :class="card.class"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <a
              @click="$router.push({ name: 'Solucoes' })"
              class="btn btn-orange-solid btn-white-solid"
              style="
                background-color: #e5eaea;
                border-bottom: #c2c2c2 0.5px solid;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
              "
              >ver todos os módulos</a
            >
          </div>
        </section>

        <!-- Abre secao das Shinier -->
        <section
          class="list list-new-destaques solucoes"
          style="margin-bottom: 2%"
        >
          <div id="shinier" class="container">
            <h2 class="title1 title-shadow-span">
              <span class="shadow-span">
                Por quê
                <b class="notranslate">SHINIER</b>?
              </span>
            </h2>
            <p style="margin-bottom: 10px">
              O conceito de brilho ao negócio vem de nossa metodologia única de
              avaliação, inspecionando os diferentes setores do seu negócio
              atribuindo a cada variável (processo e nível) uma cor de aderência
              com perguntas chaves, e métricas já consolidadas no mercado
              econômico, processos de produção e técnicas de cada setor, assim
              chegamos nos pontos onde seu negócio tem maior potencial de ganhar
              brilho, ou seja, escalabilidade, tendo um comparativo com seus
              principais competidores.
            </p>

            <p style="margin-bottom: 10px">
              Sobre nossas principais soluções além de trabalhar em parcerias
              com outras empresas de desenvolvimento tecnológico, possuímos
              nossas soluções que são como joias a serem entregues, pois
              passaram por um processo de lapidação em ambientes real, a
              experiência dos coordenadores de cada projeto foi essencial para
              chegarmos a sistemas com alto-nível de excelência, e reduzir e
              prever os pontos de falhas do sistema ou processos, e possuir uma
              modularização de maneira a agilizar a implantação aos mais
              diversos mercados, hoje chega a sua mão uma solução com um brilho
              de uma verdadeira joia a ser integrada ao desenvolvimento escalar
              do seu negócio, entre em contato conosco e agende uma consultoria.
            </p>
            <a
              @click="$router.push({ name: 'Shinier' })"
              class="btn btn-news-geral btn-orange-solid"
              style="
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                padding: 0.5%;
              "
              >Saiba mais</a
            >
          </div>
        </section>

        <section
          class="list list-new-destaques solucoes"
          style="background-color: white"
        >
          <div class="container" style="width: 100%">
            <h2 class="title1 title-solucao">
              <span
                class="shadow-span"
                style="background-color: var(--cor-cinza-claro)"
              >
                Mídias <b>Shinier</b>
              </span>
            </h2>

            <div class="slick-destaques slick-initialized slick-slider">
              <div aria-live="polite" class="slick-list draggable">
                <!--  <div class="row"> -->
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <CardArtigoHome
                    title="ESCALABILIDADE"
                    subtitle="Como crescer meu negócio rápido"
                    titleImage="gestao-image2 imp-economico"
                    route="Artigo01"
                    imgIcon="red"
                    :relevance="1"
                    imgCard="imp-economico-card"
                    imgAlt="artigo: Como crescer meu negócio rápido"
                    desc="A Escalabilidade é um fator determinante para o futuro das empresas em fase inicial e StartUps,
        as quais dependem desse fator para crescerem.
        Apesar de sua grande importância, o estudo da Escalabilidade ainda é pouco
        explorado no mercado e no meio acadêmico no Brasil."
                    author="Marcius Leandro Junior, CEO SHINIER"
                    datePub="20/07/2021"
                    keywords="Negócios; Empreendedorismo; Maturidade; Startup."
                  />
                </div>

                <b-row>
                  <h3 class="text-uppercase text-center">
                    Ler os últimos artigos
                  </h3>
                  <div
                    class="col-xl-2 col-lg-3 col-md-6 col-sm-12"
                    v-for="(card, index) in cardRel"
                    :key="index"
                  >
                    <CardRelacionado
                      :title="card.title"
                      :subtitle="card.subtitle"
                      :keywords="card.keywords"
                      :relevance="card.relevance"
                      :imgIcon="card.imgIcon"
                      :route="card.route"
                    />
                  </div>
                  <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12">
                    <a @click="$router.push('/artigos')"
                      ><div
                        class="card-relacionado"
                        style="background: var(--cor-primaria)"
                      >
                        <p class="p-card-all-articles">Ver todos os artigos</p>
                      </div></a
                    >
                  </div>
                </b-row>
                <b-row>
                  <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                    <h3
                      style="color: #5782ad"
                      class="text-center text-uppercase"
                    >
                      SIGA NOSSAS MÍDIAS SOCIAIS !
                    </h3>
                  </div>
                </b-row>
                <b-row>
                  <SocialMedia />
                </b-row>
              </div>
            </div>
            <a
              style="
                background-color: #e5eaea;
                border-bottom: #c2c2c2 0.5px solid;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
              "
              @click="subscribeNews()"
              class="btn btn-orange-solid btn-white-solid"
              >Assinar newsletter</a
            >
          </div>
        </section>
      </main>
      <!--  -->
    </div>
  </div>
</template>

<script>
import scrollToTop from "../../mixins/scrollToTop";
import categoria from "../../store/modules/solucoes";
/* carousel 3d */
import { Carousel3d, Slide } from "vue-carousel-3d";
/* carousel normnal */
import { Carousel, Slide as SlideNormal } from "vue-carousel";
import showCards from "../../mixins/showCards";
import CardArtigoHome from "../../components/Midias/CardArtigoHome";
import SocialMedia from "../../components/Midias/SocialMedia/index.vue";
import Planos from "../../components/Planos/index.vue";
import subscribeNews from "../../mixins/subscribeNews";
import choosePlan from "../../mixins/choosePlan";
export default {
  name: "Home",
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Shinier | Desenvolvimento de Tecnologia Escalar",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Na Shinier levamos a tecnologia do seu negócio do mínimo produto viável ao escalar. Atuamos no desenvolvimento de código nativo para App, Web, API e etc. Tudo por um valor mensal acessível."
      },

      {
        name: "twitter:card",
        content: "summary"
      },
      {
        name: "twitter:title",
        content: "Shinier | Desenvolvimento de Tecnologia Escalar"
      },
      { property: "og:type", content: "article" },
      {
        property: "og:title",
        content: "Shinier | Desenvolvimento de Tecnologia Escalar"
      },
      {
        property: "og:url",
        content: "https://shinier.com.br/"
      },
      {
        property: "og:description",
        content:
          "Na Shinier levamos a tecnologia do seu negócio do mínimo produto viável ao escalar. Atuamos no desenvolvimento de código nativo para App, Web, API e etc. Tudo por um valor mensal acessível."
      },
      {
        property: "og:image",
        content: "https://shinier.com.br/img/shinier_logo.e58b8f03.svg"
      },
      { property: "og:image:width", content: "400" },
      { property: "og:image:height", content: "400" },
      {
        name: "twitter:image:src",
        content: "https://shinier.com.br/img/shinier_logo.e58b8f03.svg"
      },
      {
        name: "twitter:description",
        content:
          "Na Shinier levamos a tecnologia do seu negócio do mínimo produto viável ao escalar. Atuamos no desenvolvimento de código nativo para App, Web, API e etc. Tudo por um valor mensal acessível."
      },
      {
        property: "og:site_name",
        content: "Shinier | Desenvolvimento de Tecnologia Escalar"
      }
    ]
  },
  data() {
    return {
      win: false,
      slide: categoria.state.solucoes,
      slideClientes: [],
      settings: {
        controlsVisible: true,
        clickable: true,
        autoplay: true,
        autoplayHoverPause: true,
        animationSpeed: 700,
        display: 3
      },

      imageParceiros: [],
      imageCases: []
    };
  },
  computed: {
    slides() {
      return this.slide;
    },
    cards() {
      return this.slide.slice(0, 4);
    },
    stageCards() {
      return !this.$store.getters.stages.length
        ? this.$store.getters.stages
        : this.$store.state.stage;
    }
  },
  mounted() {
    if (window.innerWidth > 1024) {
      this.win = true;
    } else {
      this.win = false;
    }
  },
  mixins: [scrollToTop, subscribeNews, showCards, choosePlan],
  created() {
    this.imageParceiros = this.$store.getters.getAllImages.imagesParceiros;
    this.imageCases = this.$store.getters.getAllImages.imagesCases;
    this.slideClientes = this.$store.getters.getAllImages.imageCaseCarousel;
  },
  components: {
    BannerHome: () => import("../../components/Banner/BannerHome"),
    CardDestaque: () => import("@/components/Cards/CardDestaque"),
    CardSlider: () => import("../../components/Cards/CardSlider"),
    CardCase: () => import("@/components/Cards/CardCase"),
    Carousel3d,
    Slide,
    Carousel,
    SlideNormal,
    CardRelacionado: () => import("../../components/Blog/CardRelacionado"),
    CardArtigoHome,
    Planos,
    SocialMedia
  }
};
</script>
<style lang="scss" src="./index.scss" scoped></style>
