<template>
  <div>
    <button id="contact-modal-close" class="modal-close" @click="closeModel">
      <span class="modal-close"><i class="icon-close"></i></span>
    </button>

    <h1 class="title-h1"><i class="icon icon-email"></i>Contato</h1>

    <h2 class="title-h2 title-outline">
      Preencha o formulário a seguir com as suas informações e uma mensagem com
      dúvidas, ou interesses, ou entre em contato conosco por algum de nossos
      canais.
      <br />Em breve um representante
      <span class="notranslate">Shinier</span> entrará em contato com você.
    </h2>

    <div class="row content-modal">
      <form id="contact-modal-form" class="modal-form" @submit.prevent="send">
        <div class="col-sm-12 col-md-6">
          <fieldset class="fieldset">
            <legend class="fieldset-legend">Mensagem*</legend>

            <textarea
              class="modal-input modal-textarea"
              name="message"
              rows="6"
              v-model="message.message"
            ></textarea>
          </fieldset>
        </div>
        <div class="col-sm-6 col-md-6">
          <fieldset class="fieldset">
            <legend class="fieldset-legend">Nome*</legend>

            <input
              class="modal-input"
              name="name"
              type="text"
              v-model="message.name"
            />
          </fieldset>

          <fieldset class="fieldset">
            <legend class="fieldset-legend">E-mail*</legend>

            <input
              class="modal-input"
              name="email"
              type="email"
              v-model="message.email"
            />
          </fieldset>

          <fieldset class="fieldset">
            <legend class="fieldset-legend">Empresa*</legend>

            <input
              class="modal-input"
              name="empresa"
              type="text"
              v-model="message.company"
            />
          </fieldset>
        </div>
        <div class="col-sm-6 col-md-12">
          <div class="col-sm-12 col-md-4">
            <fieldset class="fieldset">
              <legend class="fieldset-legend">Telefone</legend>

              <input
                class="modal-input-optional"
                name="tel"
                type="text"
                v-mask="'(##)#####-####'"
                v-model="message.telephone"
              />
            </fieldset>
          </div>

          <div class="col-sm-12 col-md-4">
            <fieldset class="fieldset">
              <legend class="fieldset-legend">Cargo</legend>

              <input
                class="modal-input-optional"
                name="cargo"
                type="text"
                v-model="message.office"
              />
            </fieldset>
          </div>

          <div class="col-sm-12 col-md-4">
            <fieldset class="fieldset">
              <legend class="fieldset-legend">Site</legend>

              <input
                class="modal-input-optional"
                name="site"
                type="text"
                v-model="message.site"
              />
            </fieldset>
          </div>
        </div>
        <button class="modal-form-btn btn">Enviar</button>
      </form>
    </div>
    <div class="row shinier-content-modal">
      <div class="col-sm-4 col-md-4">
        <i class="icon-email"></i>
        <span>Email:</span>
        <a href="mailto: contato@shinier.com.br" class="notranslate"
          >contato@shinier.com.br</a
        >
      </div>
      <div class="col-sm-4 col-md-4">
        <i class="icon-whatsapp"></i>
        <span>WhatsApp:</span>
        <a href="https://wa.me/5516981354817">(16)98135-4817</a> /
        <a href="https://wa.me/5519992681213">(19)99268-1213</a>
      </div>
      <div class="col-sm-4 col-md-4">
        <i class="icon-linkedin"></i>
        <span>Linkedin:</span>
        <a
          href="https://www.linkedin.com/company/shiniersolution/"
          class="notranslate"
          >shinier.sistemas</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services";
import { toast } from "@/utils";
import $ from "jquery";
import store from "../../store";

export default {
  props: ["page"],
  data: () => ({
    message: {
      name: "",
      company: "",
      email: "",
      telephone: "",
      office: "",
      site: "",
      page: "",
      message: "",
      tracking: ""
    }
    // show: false, // password show/hide eye
  }),
  methods: {
    closeModel: function() {
      let fade = document.getElementById("fade");
      fade.style.display = "none";
    },
    send: function() {
      this.message.page = this.page;
      if (store.getters.isAccept) {
        this.message.tracking = store.getters.tracking;
      }
      let message = {};
      $.each(this.message, function(key, value) {
        if (value.length > 0) {
          message[key] = value;
        }
      });
      this.message = message;
      api.post("message/create", this.message).then(
        response => {
          if (response.status === 200) {
            toast(
              this,
              "is-success",
              "Em breve entraremos em contato com você!"
            );
            this.closeModel();
          }
        },
        error => {
          error.response.data.errors.forEach(msg => {
            toast(this, "is-danger", msg);
          });

          return Promise.reject(error);
        }
      );
      window.open(
        `https://api.whatsapp.com/send?phone=5516981354817&text=${this.message}`
      );
    }
  }
};
</script>
<style lang="sass">
@import "./modal.scss"
</style>
