<template>
  <div
    style="
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      flex-wrap: nowrap;
      margin: auto;
      padding: 2%;
    "
  >
    <div
      class="col-xl-3 col-lg-3 col-md-6 col-sm-12"
      v-for="(img, index) in images"
      :key="index"
    >
      <a :href="img.href">
        <img
          style="width: 70%"
          :id="img.id"
          :src="img.src"
          :alt="img.alt"
          srcset=""
        />
        <caption
          style="
            font-size: 15px;
            text-align: center;
            position: absolute;
            padding: 10%;
            color: #6E6E6E;
          "
        >
          {{
            img.alt
          }}
        </caption>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        {
          id: 1,
          alt: "Facebook",
          src: require("../../../assets/images/facebook 1.svg"),
          href: "https://www.facebook.com/shiniersolution"
        },
        {
          id: 2,
          alt: "LinkedIn",
          src: require("../../../assets/images/linkedin 1.svg"),
          href: "https://www.linkedin.com/company/shiniersolution/"
        },
        {
          id: 3,
          alt: "Instagram",
          src: require("../../../assets/images/instagram 1.svg"),
          href: "https://instagram.com/shiniersolution"
        }
        /* {
          id: 4,
          alt: "Youtube",
          src: require("../../../assets/images/youtube 1.svg"),
          href: "",
        }, */
      ]
    };
  }
};
</script>
